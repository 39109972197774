import { socket } from "./index";
export const socketEvents = ({ setValue }) => {
  socket.on("mang", ({ mang }) => {
    if (mang) {
      localStorage.setItem("mang", JSON.stringify(mang));
    }
    setValue((state) => {
      return { ...state, mang };
    });
  });

  socket.on("login", (data) => {});

  socket.on("soketLoginServer", ({ mang }) => {
    if (mang) {
      localStorage.setItem("mang", JSON.stringify(mang));
    }
    setValue((state) => {
      return { ...state, mang };
    });
  });

  socket.on("soketLogout", () => {
    localStorage.removeItem("mang");
    setValue((state) => {
      return { ...state, mang: [] };
    });
  });

  socket.on("soketStart", ({ mang }) => {
    setValue((state) => {
      return { ...state, mang };
    });
  });
  
  
  socket.on("nono", () => {
	localStorage.removeItem("mang");
    setValue((state) => {
      return { ...state, mang: [] };
    });
  })
  
};
