const easing = [0.6, -0.05, 0.01, 0.99];

export const loginKast = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.6,
      duration: 0.6,
      ease: easing,
    },
  },
  closed: { y: 60, opacity: 0 },
};

export const mustLogo = {
  open: {
    y: 0,
    opacity: 1,
    rotate: 0,
    transition: {
      delay: 1.2,
      duration: 0.6,
      ease: easing,
    },
  },
  closed: { y: 300, zIndex: 1, rotate: 90, opacity: 0 },
};

export const valgeLogo = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 1.2,
        duration: 0.6,
        ease: easing,
      },
    },
    closed: { y: 30, opacity: 0 },
  },
  ylemineLill = {
    open: {
      x: 0,
      y: 0,
      opacity: 1,
      rotate: 0,
      transition: {
        delay: 0,
        duration: 0.6,
        ease: easing,
      },
    },
    closed: { y: -300, x: 300, opacity: 0 },
  };

export const alumineLill = {
  open: {
    x: 0,
    y: 0,
    opacity: 1,
    rotate: 0,
    transition: {
      delay: 0,
      duration: 0.6,
      ease: easing,
    },
  },
  closed: { y: 300, x: -300, opacity: 0 },
};

export const kaed = {
  open: {
    opacity: 1,
    transition: {
      delay: 1.6,
      duration: 5,
      ease: easing,
    },
  },
  closed: { opacity: 0 },
};
