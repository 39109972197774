import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import ScrollToTop from "react-router-scroll-top";

import PrivateRouteLayout from "./Components/PrivateRouteLayout";
import PrivateRoute from "./Components/PrivateRoute";

//import Sok from "./Components/Sok";

import SocketProvider from "./Components/socket_context";

import Login from "./Pages/Login/Login";
import Start from "./Pages/Start/Start";

import Eellugu from "./Pages/Eellugu/Eellugu";
import KKK from "./Pages/KKK/KKK";
import VotaYhendust from "./Pages/VotaYhendust/VotaYhendust";
import Asitoendid from "./Pages/Asitoendid/Asitoendid";

import Pakk0 from "./Pages/Pakid/Pakk0";
import Pakk1 from "./Pages/Pakid/Pakk1";
import Pakk2 from "./Pages/Pakid/Pakk2";
import Pakk3 from "./Pages/Pakid/Pakk3";
import Pakk4 from "./Pages/Pakid/Pakk4";
import Pakk5 from "./Pages/Pakid/Pakk5";
import Pakk6 from "./Pages/Pakid/Pakk6";
import Pakk7 from "./Pages/Pakid/Pakk7";
import Pakk8 from "./Pages/Pakid/Pakk8";

import AvaPakk1 from "./Pages/Pakid/AvaPakk1";
import AvaPakk2 from "./Pages/Pakid/AvaPakk2";
import AvaPakk3 from "./Pages/Pakid/AvaPakk3";
import AvaPakk4 from "./Pages/Pakid/AvaPakk4";
import AvaPakk5 from "./Pages/Pakid/AvaPakk5";
import AvaPakk6 from "./Pages/Pakid/AvaPakk6";
import AvaPakk7 from "./Pages/Pakid/AvaPakk7";
import AvaPakk8 from "./Pages/Pakid/AvaPakk8";
import AvaPakk9 from "./Pages/Pakid/AvaPakk9";

import Lopp from "./Pages/Lopp/Lopp";
import KontoOnLukus from "./Pages/KontoOnLukus/KontoOnLukus";

import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

import "./App.css";

const options = {
  position: positions.BOTTOM_RIGHT,
  timeout: 3000,
  transition: transitions.SCALE,
};

export const App = () => {
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <SocketProvider>
        <Router>
          <ScrollToTop>
            <PrivateRoute path="/" component={AvaPakk1} />
            <PrivateRoute path="/" component={AvaPakk2} />
            <PrivateRoute path="/" component={AvaPakk3} />
            <PrivateRoute path="/" component={AvaPakk4} />
            <PrivateRoute path="/" component={AvaPakk5} />
            <PrivateRoute path="/" component={AvaPakk6} />
            <PrivateRoute path="/" component={AvaPakk7} />
            <PrivateRoute path="/" component={AvaPakk8} />
            <PrivateRoute path="/" component={AvaPakk9} />
            <Switch>
              <PrivateRouteLayout exact path="/pakk/0" component={Pakk0} />
              <PrivateRouteLayout exact path="/pakk/1" component={Pakk1} />
              <PrivateRouteLayout exact path="/pakk/2" component={Pakk2} />
              <PrivateRouteLayout exact path="/pakk/3" component={Pakk3} />
              <PrivateRouteLayout exact path="/pakk/4" component={Pakk4} />
              <PrivateRouteLayout exact path="/pakk/5" component={Pakk5} />
              <PrivateRouteLayout exact path="/pakk/6" component={Pakk6} />
              <PrivateRouteLayout exact path="/pakk/7" component={Pakk7} />
              <PrivateRouteLayout exact path="/pakk/8" component={Pakk8} />
              <PrivateRouteLayout
                exact
                path="/vota_yhendust"
                component={VotaYhendust}
              />

              <PrivateRouteLayout
                exact
                path="/asitoendid"
                component={Asitoendid}
              />
              <PrivateRouteLayout exact path="/kkk" component={KKK} />
              <PrivateRouteLayout exact path="/eellugu" component={Eellugu} />
              <PrivateRoute exact path="/start" component={Start} />
              <PrivateRoute exact path="/lopp" component={Lopp} />
              <Route exact path="/konto_on_lukus" component={KontoOnLukus} />
              <Route exact path="/" component={Login} />
            </Switch>
          </ScrollToTop>
        </Router>
      </SocketProvider>
    </AlertProvider>
  );
};

export default App;
