import React from "react";

import { Link } from "react-router-dom";

import "./Menu.css";

const Menu = () => (
  <section className="menu">
    <ul>
      <li>
        <Link to="/eellugu">
          <img alt="eellugu" src="/images/eellugu.svg" />
          Eellugu
        </Link>
      </li>
      <li>
        <Link to="/asitoendid">
          <img alt="asitõendid" src="/images/asitoendid.svg" />
          Asitõendid
        </Link>
      </li>
    </ul>
  </section>
);

export default Menu;
