import React from 'react';

import Main from '../Main/Main';
import Topbar from '../Topbar/Topbar';

import './Right.css';

const Right = (props) => {
  return (
    <div className='right-container'>
      <Topbar />
      <Main>{props.children}</Main>
    </div>
  );
};

export default Right;
