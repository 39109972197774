import React, { useContext } from "react";
import { motion } from "framer-motion";

import SocketContext from "../../Components/socket_context/context";
import { soketLogout } from "../../sockets/emit";

import "./KontoOnLukus.css";

const KontoOnLukus = () => {
  const { mang } = useContext(SocketContext);
  const logiValjaBtn = (e) => {
    soketLogout(mang._id);
  };

  return (
    <motion.div
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      className="konto-on-lukus-page"
    >
      <div className="start-container">
        <div className="start-inside-container">
          <h1>Konto on lukus!</h1>
          <p>Seda koodi ei saa enam kasutada!</p>
          <div className="button-container">
            <button onClick={logiValjaBtn}>Tagasi esilehele</button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default KontoOnLukus;
