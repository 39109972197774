import React, { useContext } from "react";

import AsitoendiItem from "../AsitoendiItem/AsitoendiItem";
import SocketContext from "../../Components/socket_context/context";

import "./AsitoendiList.css";

const AsitoendiList = (props) => {
  const { mang } = useContext(SocketContext);
  return (
    <ul className="Asitoendi_list_container">
      {mang.pakid.map((pakk) =>
        pakk.id !== 9 ? (
          <li key={pakk.id}>
            <AsitoendiItem {...pakk} />
          </li>
        ) : (
          ""
        )
      )}
    </ul>
  );
};

export default AsitoendiList;
