import React from "react";

import Sidebar from "../Sidebar/Sidebar";
import Right from "../Right/Right";

import "./Layout.css";

const Layout = (props) => {
  //const { currentUser } = useContext(AuthContext);

  //  if (currentUser) {

  return (
    <div className="layout-container">
      <Sidebar />
      <Right>{props.children}</Right>
    </div>
  );
  /*} else {
    return <>{props.children}</>;
  }*/
};

export default Layout;
