import React from "react";

import { motion } from "framer-motion";

const KKK = () => {
  return (
    <motion.div initial="closed" animate="open" variants={sisu}>
      <div className="kkk">
        <h1 aria-hidden="true" className="title">
          KKK
        </h1>
        <h3 aria-hidden="true" className="subtitle"></h3>
        <div className="kast">
          <ol>
            <li>
              <b>Mis on online põgenemistuba?</b>
              <br />
              Online põgenemistuba on veebis lahendatav põgenemistuba. Võid seda
              teha üksi või koos sõpradega. Pärast ostmist saadetakse teie
              meilile kasutajanimi ja parool. Sisse logides saate hakata koos
              lahendama ülesandeid. Võite seda teha kõik sama arvuti juures või
              igaüks enda arvutis.
            </li>
            <li>
              <b>Mis seadmes ma saan mängida?</b>
              <br />
              Meie põgenemistuba saab mängida arvutis ja tahvelarvutis. Mugavuse
              ja kiiruse pärast soovitame meie arvutit.
            </li>
            <li>
              <b>Kas mul on vaja printerit?</b>
              <br />
              Ei ole. Vajad ainult arvutit või tahvelarvutit koos
              internetiühendusega.
            </li>
            <li>
              <b>
                Kas ma saan mängida sõpradega ka nii, et me ei ole ühes ruumis?
              </b>
              <br />
              Jah. Saate kõik sisse logida sama kasutajanime ja parooliga.
              Küsitud vihjeid ja maha tõmmatud kahtlusaluseid näevad reaalajas
              kõik.
            </li>
            <li>
              <b>Kui ma mängu ostan, kas saan hakata kohe mängima?</b>
              <br />
              Jah, nii kui makse on edukalt sooritatud, tuleb teie
              meiliaadressile kasutajanimi ja parool, millega saate teie ja kõik
              tiimiliikmed sisse logida.{" "}
              <b>
                Oluline on siinjuures see, et pärast sisse logimist ja “Start”
                nupu vajutamist hakkab jooksma alla 48 tundi, mille jooksul
                saate mängu mängida. Kindlasti vajutage “Start” nuppu alles
                siis, kui plaanite hakata mängima.
              </b>
            </li>
            <li>
              <b>Kuidas ma saan vihjeid?</b>
              <br />
              Vihjed on mängu sees juba olemas. Te ei pea selleks eraldi meile
              kirjutama. Iga ülesande juures on enne mitu vihjet ja lõpus
              vastus. Kui üks mängijatest avab vihje, näevad seda kõik.
            </li>
            <li>
              <b>Mis siis saab, kui ma ei saa hetkel edasi mängida?</b>
              <br />
              Võite panna mängu kinni ja hiljem edasi lahendada. Juba lahendatud
              ülesanded ära ei kao. Oluline on see, et lõpetaksite mängu enne 48
              tundi.{" "}
              <b>
                48 tundi hakkab jooksma hetkest, kui logite sisse ja vajutate
                nuppu “Start”.
              </b>
            </li>
            <li>
              <b>Kui kaua ma saan põgenemistuba lahendada?</b>
              <br />
              Põgenemistuba saate lahendada 48 tundi. Aeg hakkab jooksma alates
              ”Start” nupu vajutamisest. Võite vahepeal mängust välja logida ja
              hiljem uuesti tagasi tulla, aga sellel ajal jookseb aeg alla. Kui
              48 tundi saab täis, ei saa te enam samade paroolidega siseneda.
            </li>
            <li>
              <b>Kas mängu saab vahepeal panna pausile?</b>
              <br />
              Ei. Pärast “Start” nupu vajutamist hakkab 48 tundi alla jooksma.
              Saate vahepeal mängu küll sulgeda ja hiljem tagasi tulla, aga aeg
              jookseb endiselt alla. Peate põgenema 48 tunni jooksul.
            </li>
            <li>
              <b>Kas ma saan mitu korda mängida?</b>
              <br />
              Ei. Kui olete ühe korra ülesande lahendanud ei saa seda enam n-ö
              kinni panna.
            </li>
          </ol>
        </div>
      </div>
    </motion.div>
  );
};

export default KKK;

const easing = [0.6, -0.05, 0.01, 0.99];

export const sisu = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0,
      duration: 0.4,
      ease: easing,
    },
  },
  closed: { y: 60, opacity: 0 },
};
