import React, { useContext } from "react";
import { Link, Redirect } from "react-router-dom";

//import { useTracker } from 'meteor/react-meteor-data';
import { motion } from "framer-motion";

import SocketContext from "../../Components/socket_context/context";
import { soketLogout } from "../../sockets/emit";

//import { UserContext } from "../UserContext";

import PaljuAega from "../PaljuAega/PaljuAega";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import "./Topbar.css";

//const Completionist = () => <span>You are good to go!</span>;

const Topbar = (props) => {
  const { mang } = useContext(SocketContext);
  /*
  if(mang.mangLukus === true){
	  return <Redirect to="/konto_on_lukus" />;
  }*/
  
  //  const { setUser } = useContext(UserContext);
  //const [setKell] = useState(1);

  const logiValja = (e) => {
    e.preventDefault();
    console.log("Logi välja");
    confirmAlert({
      title: "Oled kindel,",
      message: "et soovid logida välja?",
      buttons: [
        {
          label: "Jah",
          onClick: () => soketLogout(mang._id),
        },
        {
          label: "Ei",
        },
      ],
    });
    // setUser({});
  };
  // <PaljuAega />;
  return (
    <motion.div
      initial="closed"
      animate="open"
      variants={topbar_animation}
      className="TopbarContainer"
    >
      <PaljuAega />
      <ul>
        <li>
          <Link to="/KKK">
            <img alt="KKK" src="/images/info.svg" /> KKK
          </Link>
        </li>
        <li>
          <Link to="/vota_yhendust">
            <img alt="Võta ühendust" src="/images/chat.svg" /> Võta ühendust
          </Link>
        </li>
        <li>
          <button onClick={logiValja}>
            <img alt="Logi välja" src="/images/logoff.svg" /> Logi välja
          </button>
        </li>
      </ul>
    </motion.div>
  );
};

//
export default Topbar;

const easing = [0.6, -0.05, 0.01, 0.99];

const topbar_animation = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.3,
      duration: 0.4,
      ease: easing,
    },
  },
  closed: { y: -100, opacity: 0 },
};
