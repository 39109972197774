import React from "react";
import { motion } from "framer-motion";
import Menu from "../Menu/Menu";
import Kahtlusalused from "../Kahtlusalused/Kahtlusalused";

import "./Sidebar.css";

const Sidebar = () => {
  return (
    <motion.div
      initial="closed"
      animate="open"
      variants={sidebar_animation}
      className="SidebarContainer"
    >
      <img
        alt="Can you escape?"
        className="logo"
        src="/images/kollane_valge_logo.svg"
      />
      <Menu />
      <Kahtlusalused />
    </motion.div>
  );
};

export default Sidebar;

const easing = [0.6, -0.05, 0.01, 0.99];

const sidebar_animation = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0,
      duration: 0.4,
      ease: easing,
    },
  },
  closed: { x: -300, opacity: 0 },
};
