import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

import Layout from "./Layout/Layout";

import SocketContext from "./socket_context/context";
import { socketJoin } from "../sockets/emit";

const PrivateRouteLayout = ({ component: RouteComponent, ...rest }) => {
  const { mang } = useContext(SocketContext);

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (mang && mang._id) {
          socketJoin(mang._id);
          return (
            <Layout>
              <RouteComponent {...routeProps} />
            </Layout>
          );
        } else {
          return <Redirect to={"/"} />;
        }
      }}
    />
  );
};

export default PrivateRouteLayout;
