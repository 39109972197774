import React from 'react';

import { Link } from 'react-router-dom';

import './AsitoendiItem.css';

const AsitoendiItems = ({
  pealkiri,
  sub,
  lukk,
  vihjeid_kokku,
  locked,
  link,
  lockedLink,
  unlockLink,
  pid,
}) => {
  return (
    <div className={`asitoendi_item_container ${locked ? 'locked' : null}`}>
      <div className='ylemine'>
        {locked ? (
          <img alt='a' src='/images/locked.svg' className='locked' />
        ) : null}
        <img alt='a' src='/images/asitoendi_item_pilt.png' />
        <h2>{pealkiri}</h2>
        <p>{sub}</p>
      </div>
      <div className='alumine'>
        <ul>
          {locked ? (
            <li key='1'>
              {pid === 4 || pid === 7 || pid === 8 ? (
                <img alt='a' src='/images/persoonid.svg' />
              ) : (
                <img alt='a' src='/images/lock.svg' />
              )}
              <b>{lukk}</b> numbrit
            </li>
          ) : null}
          <li key='2'>
            <img alt='a' src='/images/flag.svg' />
            <b>{vihjeid_kokku}</b> vihjet
          </li>
        </ul>

        {locked ? (
          <Link to={{ pathname: '/asitoendid', search: unlockLink }}>
            <button>AVA</button>
          </Link>
        ) : (
          <Link to={link}>
            <button>SISENE</button>
          </Link>
        )}
      </div>
    </div>
  );
};

//'?ava_pakk_1=true'

export default AsitoendiItems;
