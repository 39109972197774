import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import "../Pakid/Pakid.css";

const Eellugu = () => {
  return (
    <motion.div initial="closed" animate="open" variants={sisu}>
      <div className="eelluguPage">
        <h1 aria-hidden="true" className="title">
          Eellugu
        </h1>
        <h3 aria-hidden="true" className="subtitle"></h3>
        <div className="kast">
          <b>
            <big>Enne mängima hakkamist:</big>
          </b>

          <ul style={{ marginLeft: "20px", marginTop: "20px" }}>
            <li>Alustage asitõendist “Kiri kliendilt”.</li>
            <li>
              <b>
                Asitõendite pakist leitud vihjed/pildid/failid (ka need, mida
                olete juba kasutanud), võivad abistada teid ka järgmistes
                ülesannetes.
              </b>
            </li>
            <li>
              Iga asitõendi paki peal on kirjas, kas peate otsima
              numbrikombinatsiooni või hoopis nime.
            </li>
            <li>
              Vihjeid saate asitõendi paki seest. Kui olete ühe vihje võtnud,
              siis 10 sekundit uut vihjet võtta ei saa. See tagab selle, et
              samaaegselt tiimikaaslastega vajutades, ei ava te kogemata kõiki
              vihjeid korraga.
            </li>
            <li>
              Kui paned neli korda koodi valesti, siis pead ootama 2 minutit.
            </li>
            <li>
              Vihjete kõrval olevad lipukesed näitavad mitu vihjet on võimalik
              saada. <b>NB! Punase lipuga on märgitud vastus.</b>
            </li>
            <li>
              Vasakul olevas külgmenüüs on kahtlusaluste nimekiri. Ühele nimele
              klikates muutub see tuhmiks ehk see inimene ei ole enam
              kahtlusalune teie arvates. Uuesti klikates on nimi taas aktiivne.
              Kõik mängijad näevad reaalajas muudatusi. NB! Nimekiri on teile
              abiks ega muuda mängu kulgu.
            </li>
            <li>
              <b>Teil on võimalus ühe parooliga mängida 48 tundi.</b> Ka siis,
              kui otsustate mängu pooleli jätta, jookseb aeg edasi.
            </li>
            <li>Kui logite mängust välja, ei kao juba lahendatud ülesanded.</li>
          </ul>
          <br />
          <hr style={{ border: "1px dashed lightgray" }} />
          <br />
          <p>
            Sind ja sinu sõpru on palutud uurima mõrva, mida ajakirjandus
            nimetab “Surmavaks sünnipäevaks”. Sünnipäevalapse parim sõbranna
            soovib juhtumisse selgust tuua ja teada saada, kes ning miks tappis
            Heidi Gatesi. Selleks peate lahendama ülesandeid, et avada uusi
            asitõendeid ja jõuda lahenduseni, miks selline tegu toime pandi.
          </p>
        </div>

        <div className="kastButtons">
          <div className="vihjeButtonKast"></div>

          <Link to="/asitoendid">
            <button>
              Alusta <img alt="a" src="/images/canyouescape_icon.svg" />
            </button>
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default Eellugu;

const easing = [0.6, -0.05, 0.01, 0.99];

export const sisu = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0,
      duration: 0.4,
      ease: easing,
    },
  },
  closed: { y: 60, opacity: 0 },
};
