import React, { useContext } from "react";
import { motion } from "framer-motion";
import SocketContext from "../../Components/socket_context/context";
import { soketLogout } from "../../sockets/emit";
import moment from "moment";
import "./Lopp.css";

const Lopp = () => {
  const { mang } = useContext(SocketContext);
  const logiValjaBtn = (e) => {
    soketLogout(mang._id);
  };

  var then = mang.startTime;
  var now = mang.stopTime;

  var ms = moment(now).diff(moment(then));
  var d = moment.duration(ms);
  var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");

  /*
  var date1 = moment(mang.startTime);
  var date2 = moment(mang.stopTime);

  var diff = date2.diff(date1).format(" h:mm:ss ");

  var diffInMinutes = date2.diff(date1, "seconds");*/

  return (
    <motion.div
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      className="lopp"
    >
      <div className="start-container">
        <div className="start-inside-container">
          <h1>Palju õnne!</h1>
          <p>
            Juhtum on tänu teile edukalt lahendatud! Nagu te juba teate, siis
            mõrvariteks osutusid õed Carrie, Abigail ja Hollie. Võid juba aimata
            ka põhjust - kättemaks. Neljandas pakis olnud uudisest saame teada,
            et 22-aastane naine põhjustas liiklusõnnetuse, mille tagajärjel suri
            Mike Davenport, kolme õe isa.
          </p>
          <br />
          <p className="statistika">
            <div>
              <b>{s}</b>
              <span>Aega kulus</span>
            </div>

            <div>
              <b>{mang.mituVihjetVoeti}</b>
              <span>Vihjeid võeti</span>
            </div>
          </p>
          <br />
          <p>Palun leidke aega, et anda väike tagasiside <a style={{ color: '#fff' }} href="https://forms.gle/MrQPVaPHPRBwLZyb9">LINK</a>.</p>
          <br />

          <div className="button-container">
            <button onClick={logiValjaBtn}>Logi välja</button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Lopp;
