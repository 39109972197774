import React, { useState, useContext } from 'react';
import Modal from '../../Components/Modal';
import { useAlert } from 'react-alert';
import Countdown from 'react-countdown';
import moment from 'moment';

import SocketContext from '../../Components/socket_context/context';
import {
  soketValeLukk,
  soketLukkVabaks,
  soketOigeVastus,
} from '../../sockets/emit';

import './AvaPakk.css';

const AvaPakk2 = (props) => {
  const id = 2;
  const { mang } = useContext(SocketContext);
  const pakk = mang.pakid[id];
  const teade = useAlert();
  const [lukk1, setlukk1] = useState(0);
  const [lukk2, setlukk2] = useState(0);
  const [lukk3, setlukk3] = useState(0);
  const [lukk4, setlukk4] = useState(0);

  const params = new URLSearchParams(props.location.search);

  if (params.get('ava_pakk') === '2' && pakk.locked === false) {
    props.history.push('/pakk/2');
  }

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      soketLukkVabaks({ _id: mang._id, pakk_id: id });
      return <></>;
    } else {
      return (
        <>
          <div className='lukk_lukus'>
            <img alt='a' src='/images/lukk_lukus.png' />
            <div className='lukk_lukkus_text'>
              <b>{minutes * 60 + seconds}</b>
              <br />
              sekundit
            </div>
          </div>
        </>
      );
    }
  };

  const lisa_48_tundi = 60 * 2 * 1000;
  const algus_kell = moment(pakk.lukk_lukkus);
  const kk = algus_kell + lisa_48_tundi;

  const suurenda = (lukk, setlukk) => {
    var uus_number = lukk + 1;
    if (uus_number > 9) {
      uus_number = 0;
    }
    setlukk(uus_number);
  };

  const vahenda = (lukk, setlukk) => {
    var uus_number = lukk - 1;
    if (uus_number < 0) {
      uus_number = 9;
    }

    setlukk(uus_number);
  };

  const kontrolliVastust = (e) => {
    const vastus = lukk1 + '' + lukk2 + '' + lukk3 + '' + lukk4;

    if (vastus === '8565') {
      teade.success('Õige vastus!');
      soketOigeVastus({ _id: mang._id, pakk_id: id });
    } else {
      teade.error('Vale vastus!');
      setlukk1(0);
      setlukk2(0);
      setlukk3(0);
      setlukk4(0);

      soketValeLukk({ _id: mang._id, pakk_id: id });
    }
  };

  return (
    params.get('ava_pakk') === '2' && (
      <Modal onClick={(e) => props.history.push(props.location.pathname)}>
        <button
          className='close-btn'
          onClick={(e) => props.history.push(props.location.pathname)}
        >
          x
        </button>
        <img alt='Can you escape?' className='lukk' src='/images/lukk.svg' />
        {pakk.lukk_lukkus ? (
          <>
            <h1>Tuleb oodata!</h1>
            <p
              style={{
                color: 'red',
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
            >
              Sisestasid liiga palju valesid vastuseid!
            </p>
          </>
        ) : (
          <>
            <h1>AVA LUKK</h1>
            <p>Sisesta õige kombinatsioon ja klikka "Kinnita"</p>
          </>
        )}
        {pakk.lukk_lukkus ? (
          <Countdown
            daysInHours={false}
            date={new Date(kk)}
            renderer={renderer}
          />
        ) : (
          <div className='luku_rida'>
            <div className='lukk_input'>
              <button onClick={(e) => suurenda(lukk1, setlukk1)}>
                <img alt='Can you escape?' src='/images/up.svg' />
              </button>
              <div>{lukk1}</div>
              <button onClick={(e) => vahenda(lukk1, setlukk1)}>
                <img alt='Can you escape?' src='/images/bottom.svg' />
              </button>
            </div>
            <div className='lukk_input'>
              <button onClick={(e) => suurenda(lukk2, setlukk2)}>
                <img alt='Can you escape?' src='/images/up.svg' />
              </button>
              <div>{lukk2}</div>
              <button onClick={(e) => vahenda(lukk2, setlukk2)}>
                <img alt='Can you escape?' src='/images/bottom.svg' />
              </button>
            </div>
            <div className='lukk_input'>
              <button onClick={(e) => suurenda(lukk3, setlukk3)}>
                <img alt='Can you escape?' src='/images/up.svg' />
              </button>
              <div>{lukk3}</div>
              <button onClick={(e) => vahenda(lukk3, setlukk3)}>
                <img alt='Can you escape?' src='/images/bottom.svg' />
              </button>
            </div>
            <div className='lukk_input'>
              <button onClick={(e) => suurenda(lukk4, setlukk4)}>
                <img alt='Can you escape?' src='/images/up.svg' />
              </button>
              <div>{lukk4}</div>
              <button onClick={(e) => vahenda(lukk4, setlukk4)}>
                <img alt='Can you escape?' src='/images/bottom.svg' />
              </button>
            </div>
          </div>
        )}
        {pakk.lukk_lukkus ? (
          ''
        ) : (
          <div className='button-container'>
            <button onClick={kontrolliVastust}>Kinnita</button>
          </div>
        )}
      </Modal>
    )
  );
};

export default AvaPakk2;
