import React from "react";
import { motion } from "framer-motion";

import { alumineLill, kaed, mustLogo, ylemineLill } from "./Animatsion";

const LoginBG = () => {
  return (
    <>
      <motion.div
        initial="closed"
        animate="open"
        className="keskmine"
        variants={kaed}
      />
      <motion.div initial="closed" animate="open" variants={mustLogo}>
        <img
          src="/images/must_logo.png"
          className="must_logo"
          alt="Can you escape?"
        />
      </motion.div>
      <motion.div
        initial="closed"
        className="yleminelill"
        animate="open"
        variants={ylemineLill}
      >
        <img alt="lilleke" src="/images/bg/ylemine_lill.png" />
      </motion.div>
      <motion.div
        initial="closed"
        className="aluminelill"
        animate="open"
        variants={alumineLill}
      >
        <img alt="lilleke" src="/images/bg/alumine_lill.png" />
      </motion.div>
      <motion.div
        initial="closed"
        className="vasak_kasi"
        animate="open"
        variants={kaed}
      >
        <img alt="vasak käsi" src="/images/bg/vasak_kasi.png" />
      </motion.div>
      <motion.div
        initial="closed"
        className="parem_kasi"
        animate="open"
        variants={kaed}
      >
        <img alt="parem käsi" src="/images/bg/parem_kasi.png" />
      </motion.div>
      <motion.div
        initial="closed"
        className="keskmine"
        animate="open"
        variants={kaed}
      >
        <img alt="veri" src="/images/bg/keskmine_2.png" />
      </motion.div>
    </>
  );
};

export default LoginBG;
