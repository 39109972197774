import React, { useState, useContext } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router';
import { motion } from 'framer-motion';
import { useAlert } from 'react-alert';

//import { UserContext } from "../../Components/UserContext";

import { loginKast } from './Animatsion';
import LoginBG from './LoginBG';

import './Login.css';

import SocketContext from '../../Components/socket_context/context';
import { soketLogin } from '../../sockets/emit';

const Login = () => {
  const { mang } = useContext(SocketContext);

  const [juhtum, setJuhtum] = useState('');
  const [parool, setParool] = useState('');
  const teade = useAlert();

  if (mang && mang._id && mang.mangLukus === true) {
    return <Redirect to='/konto_on_lukus' />;
  } else if (mang && mang._id && mang.start === false) {
    return <Redirect to='/start' />;
  } else if (mang && mang._id && mang.start === true) {
    return <Redirect to='/eellugu' />;
  }

  const logiSisse = (e) => {
    e.preventDefault();

    const sendData = {
      juhtum,
      parool,
    };

    if (juhtum !== '' || parool !== '') {
      axios.post(`https://api.canyouescape.eu/login`, sendData).then((res) => {
        const data = res.data;


        if (data.staatus === 'OK') {
          teade.success('Juhtumit lahendama');

          soketLogin(data.user._id);
        } else {
          setJuhtum('');
          setParool('');
          teade.error('Valed väärtused!');
        }
      });
    } else {
      teade.error('Täida väljad!');
    }
  };

  return (
    <div className='loginPage'>
      <LoginBG />
      <motion.div
        initial='closed'
        animate='open'
        variants={loginKast}
        className='login-container'
      >
        <div className='login-inside-container'>
          <p>KUTSE</p>
          <h1>Surmav sünnipäev</h1>

          <form>
            <input
              name='juhtum'
              onChange={(e) => setJuhtum(e.target.value)}
              type='text'
              placeholder='juhtumi id'
            />
            <input
              name='parool'
              autoComplete='on'
              onChange={(e) => setParool(e.target.value)}
              type='password'
              placeholder='parool'
            />
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className='input-button'
              onClick={logiSisse}
            >
              SISENE
            </motion.button>
          </form>
        </div>
      </motion.div>
      <motion.div
        initial={{ translateY: -50, opacity: 0 }}
        animate={{ translateY: 0, opacity: 1 }}
        transition={{ delay: 0 }}
        style={{ margin: 10, zIndex: 999999, position: 'absolute', right: 0, bottom: 0 }}
      >Piltide autoriõigused on kaitstud ja kasutamine ilma autori loata on keelatud!</motion.div>
    </div>
  );
};

// <button onClick={(e) => addClientToQueue()}>123</button>
//
export default Login;
