import React, { useContext } from "react";

import { motion } from "framer-motion";
import { Redirect } from "react-router";

import SocketContext from "../../Components/socket_context/context";
import { soketLogout, soketStart } from "../../sockets/emit";

import "./Start.css";

const Start = () => {
  const { mang } = useContext(SocketContext);
  const logiValjaBtn = (e) => {
    soketLogout(mang._id);
  };

  if (mang._id && mang.mangLukus === true) {
    return <Redirect to="/konto_on_lukus" />;
  } else if (mang._id && mang.start === true) {
    return <Redirect to="/eellugu" />;
  }

  const startBtn = async (e) => {
    e.preventDefault();

    soketStart(mang._id);
  };

  return (
    <motion.div
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      className="startPage"
      style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}
    >
      <div className="start-container">
        <div className="start-inside-container">
          <h1>Oled kindel?</h1>
          <p>
            Pärast "<b>Start</b>" nupu vajutamist hakkab
            <br />
            <b>48</b> tundi alla jooksma.
          </p>
          <div className="button-container">
            <button onClick={logiValjaBtn}>Logi välja</button>
            <button onClick={startBtn} className="start">
              Start
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Start;
