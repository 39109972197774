import { socket } from "./index";

export const soketLogin = (_id) => {
  socket.emit("soketLogin", _id);
};

export const soketLogout = (_id) => {
  socket.emit("soketLogout", _id);
};

export const socketJoin = (_id) => {
  socket.emit("socketJoin", _id);
};

export const soketStart = (_id) => {
  socket.emit("soketStart", _id);
};

export const muudaKahtlusalused = (data) => {
  socket.emit("muudaKahtlusalused", data);
};

export const soketAvaVihje = (data) => {
  socket.emit("soketAvaVihje", data);
};

export const soketValeLukk = (data) => {
  socket.emit("soketValeLukk", data);
};

export const soketLukkVabaks = (data) => {
  socket.emit("soketLukkVabaks", data);
};

export const soketOigeVastus = (data) => {
  socket.emit("soketOigeVastus", data);
};
