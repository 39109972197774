import React, { useContext } from "react";
import { motion } from "framer-motion";
//import { UserContext } from "../../Components/UserContext";
import KahtlusaluseNupp from "../KahtlusaluseNupp/KahtlusaluseNupp";

import SocketContext from "../socket_context/context";

import "./Kahtlusalused.css";

const Kahtlusalused = () => {
  const { mang } = useContext(SocketContext);

  return (
    <section className="kahtlusalused-section">
      <h3>
        <img alt="kahtlusalused" src="/images/kahtlusalused.svg" />
        Kahtlusalused
      </h3>
      <motion.ul
        initial="closed"
        animate="open"
        variants={kahtlusalused_animation}
      >
        {mang.kahtlusalused.map((kahtlusaline) => (
          <li key={kahtlusaline.id}>
            <KahtlusaluseNupp kahtlusaline={kahtlusaline} />
          </li>
        ))}
      </motion.ul>
    </section>
  );
};
/*
 {user.kahtlusalused.map((kahtlusaline) => (
          <li key={kahtlusaline.id}>
            <KahtlusaluseNupp kahtlusaline={kahtlusaline} />
          </li>
        ))}*/

export default Kahtlusalused;

const easing = [0.6, -0.05, 0.01, 0.99];

const kahtlusalused_animation = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
  closed: { y: 5, opacity: 0 },
};
