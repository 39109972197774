import React, { useState, useContext } from "react";
import axios from 'axios';
import { motion } from "framer-motion";
import { FaInstagram, FaFacebookSquare } from "react-icons/fa";
import { useAlert } from "react-alert";
import SocketContext from '../../Components/socket_context/context';
import "./VotaYhendust.css";

const VotaYhendust = () => {
  const { mang } = useContext(SocketContext);
  
  const [email, setEmail] = useState("");
  const [teade, setTeade] = useState("");
  const notification = useAlert();

  const onSubmit = (e) => {
    e.preventDefault();

    // teatedContainer.insert({ user, email, teade });
    console.log(mang);
     const sendData = {
	  user: mang._id,
      email,
      teade
    };
    
    axios.post(`https://api.canyouescape.eu/teade`, sendData).then((res) => {
		const data = res.data;
		
		
		if (data.staatus === 'OK') {
			setEmail("");
			setTeade("");
			notification.success("Teade saadeti ära!");		
		} else {
			setEmail("");
			setTeade("");
			teade.error('Viga!');
		}
	});
    
  

    /*alert.error("Täida kõik väljad!", {
      timeout: 2000,
    });*/
  };

  return (
    <motion.div initial="closed" animate="open" variants={sisu}>
      <div className="VotaYhendustPage">
        <h1 aria-hidden="true" className="title">
          Võta ühendust
        </h1>
        <h3 aria-hidden="true" className="subtitle"></h3>
        <div className="kast">
          <p className="yhendust">
            Meiega saab ühendust võtta ka{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/canyouescape.eu/"
            >
              <FaInstagram size="2em" />
            </a>{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/canyouescape.eu"
            >
              <FaFacebookSquare size="2em" />
            </a>
          </p>

          <form onSubmit={onSubmit} className="vota_yhendust_form">
            <label>
              E-mail:
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="e-mail"
              />
            </label>
            <label>
              Teade:
              <textarea
                value={teade}
                onChange={(e) => setTeade(e.target.value)}
                placeholder="teade"
              ></textarea>
            </label>
            <div className="buttonContainer">
              <button>Saada</button>
            </div>
          </form>
        </div>
      </div>
    </motion.div>
  );
};

export default VotaYhendust;

const easing = [0.6, -0.05, 0.01, 0.99];

const sisu = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0,
      duration: 0.4,
      ease: easing,
    },
  },
  closed: { y: 60, opacity: 0 },
};
