import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

import SocketContext from "./socket_context/context";
import { socketJoin } from "../sockets/emit";

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { mang } = useContext(SocketContext);

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (mang && mang._id) {
          socketJoin(mang._id);
          return <RouteComponent {...routeProps} />;
        } else {
          return <Redirect to={"/"} />;
        }
      }}
    />
  );
};

export default PrivateRoute;
