import React from "react";
import { motion } from "framer-motion";
import AsitoendiList from "../../Components/AsitoendiList/AsitoendiList";

import "./Asitoendid.css";

const Asitoendid = () => {
  return (
    <motion.div initial="closed" animate="open" variants={sisu}>
      <div className="asitoendid">
        <h1 aria-hidden="true" className="title">
          Asitõendid
        </h1>
        <h3 aria-hidden="true" className="subtitle"></h3>
        <AsitoendiList />
      </div>
    </motion.div>
  );
};

export default Asitoendid;

const easing = [0.6, -0.05, 0.01, 0.99];

const sisu = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0,
      duration: 0.4,
      ease: easing,
    },
  },
  closed: { y: 60, opacity: 0 },
};
