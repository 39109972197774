import React, { useContext } from "react";
import Countdown from "react-countdown";
import moment from "moment";
import { Redirect } from "react-router";

import SocketContext from "../../Components/socket_context/context";

//import { UserContext } from "../UserContext";

const PaljuAega = () => {
  const { mang } = useContext(SocketContext);
  //  const { user } = useContext(UserContext);
  const lisa_48_tundi = 60 * 60 * 48 * 1000;
  const algus_kell = moment(mang.startTime);
  const kk = algus_kell + lisa_48_tundi;

  const renderer = ({ total, days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Redirect to="/konto_on_lukus" />;
    } else {
      // Render a countdown

      if (hours === 0) {
        return (
          <>
            <b>{minutes}</b> min <b>{seconds}</b> sek
          </>
        );
      } else {
        return (
          <>
            <b>{hours + days * 24}</b> tundi <b>{minutes}</b> min
          </>
        );
      }
    }
  };

  return (
    <span>
      <img alt="palju aega järel" src="/images/time.svg" />
      <Countdown daysInHours={false} date={new Date(kk)} renderer={renderer} />
    </span>
  );
};

export default PaljuAega;
