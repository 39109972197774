import React, { useState, useContext } from "react";
import Lightbox from "react-image-lightbox";
import { motion } from "framer-motion";
import { Link, Redirect } from "react-router-dom";

import "react-image-lightbox/style.css";
import "./Pakid.css";

import SocketContext from "../../Components/socket_context/context";

import { soketAvaVihje } from "../../sockets/emit";

const Pakk0 = () => {
  const id = 0;
  const images = ["/images/KUTSE.jpg"];
  const { mang } = useContext(SocketContext);
  const [photoIndex] = useState(0);
  const [isOpen, setisOpen] = useState(false);

  /* if (!mang.pakid) {
    return <></>;
  }*/

  const pakk = mang.pakid[id];

  if (pakk.locked) {
    return <Redirect to={"/"} />;
  }

  const nextpakk = mang.pakid[id + 1];
  const vihjeid = mang.pakid[id].vihjeid;
  const vihjeid_veel = vihjeid.find((element) => !element.loetud);

  const avaVihje = async (e) => {
    e.preventDefault();
    const data = {
      _id: mang._id,
      pInx: parseInt(id),
      vInx: vihjeid_veel.id,
    };

    soketAvaVihje(data);
  };

  return (
    <motion.div initial="closed" animate="open" variants={sisu}>
      <div className="pakkPage">
        <h1 className="title">Kiri</h1>
        <h3 className="subtitle">Kliendilt</h3>

        <div className={`kast bol`}>
          Tere, detektiivid!
          <br />
          <br />
          Ma väga loodan, et teie saate mind aidata ja leiate need inimesed, kes
          tapsid minu parima sõbranna <b>H</b>eidi Gatesi ja temaga koos veel
          paljud peol viibinud inimesed. Meil oli mõnus õhtu klubis, kus
          tähistasime Heidi sünnip<b>ä</b>eva. Istusime umbes 25-30 inimesega
          VIP ruumis, kui järsku astusid sisse kolm naist ja hakkasi<b>d</b>{" "}
          tulist<b>a</b>ma. K<b>a</b> sõ<b>b</b>ranna turvamehed avasid tule. Pe
          <b>i</b>tsin ennast ruttu baarileti taha ja ootasi<b>n</b>, et see õ
          <b>u</b>dus lõppeks. Kõik karjusid ja olid paanikas. Kui olukord
          rahunes, jooksid välja ini<b>m</b>esed, kes olid ellujäänud. Sõ
          <b>b</b>rannat nende seas ei olnud. Palun aidake mul tema mõrvarid l
          <b>e</b>ida! Et anda teile kõik info<b>r</b>matsioon, mida mina tean,
          siis lisasin pildi ka Heidi sünnipäevapeo kutsest. Tegin ka nimekirja
          kahtlusalustest, kes minu arvates on kõige tõenäolisemad süüdlased.
          <ul className="failid">
            <li style={{ cursor: "pointer" }} onClick={() => setisOpen(true)}>
              <img
                alt="Can you escape?"
                className="lukk"
                src="/images/fail.svg"
              />{" "}
              Kutse
            </li>
          </ul>
        </div>

        <div className="kastButtons">
          <div className="vihjeButtonKast">
            <button
              disabled={!vihjeid_veel || mang.pakid[id].vihjeteProovimineLukus}
              onClick={avaVihje}
            >
              Vajan vihjet <img alt="a" src="/images/canyouescape_icon.svg" />
            </button>
            <div className="vihjedRow">
              <div>
                {vihjeid.map(({ id, loetud }, ind) => {
                  return (
                    <div key={id}>
                      {vihjeid.length === ind + 1 ? (
                        <img
                          alt="a"
                          key={id}
                          width="15"
                          className={loetud ? "kasutatud" : null}
                          src="/images/flag_red.svg"
                        />
                      ) : (
                        <img
                          alt="a"
                          key={id}
                          width="15"
                          className={loetud ? "kasutatud" : null}
                          src="/images/flag_black.svg"
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {nextpakk.locked ? (
            <Link to={{ pathname: "/pakk/0", search: "?ava_pakk=1" }}>
              <button>Sain koodi kätte!</button>
            </Link>
          ) : (
            <Link to="/pakk/1">
              <button>järgmine asitõend</button>
            </Link>
          )}
        </div>

        <div className="vihjed">
          <ul>
            {vihjeid.map(
              ({ id, vihje, loetud }) =>
                loetud && (
                  <li key={id}>
                    <div className="vihje_kast">
                      <img alt="a" src={`/images/vihje_${id + 1}.svg`} />{" "}
                      <span>VIHJE</span>
                      <p>{vihje}</p>
                    </div>
                  </li>
                )
            )}
          </ul>
        </div>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          onCloseRequest={() => setisOpen(false)}
          enableZoom={false}
        />
      )}
    </motion.div>
  );
};

export default Pakk0;

const easing = [0.6, -0.05, 0.01, 0.99];

const sisu = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0,
      duration: 0.4,
      ease: easing,
    },
  },
  closed: { y: 60, opacity: 0 },
};
