import React, { useState, useContext } from 'react';
import Modal from '../../Components/Modal';
import { useAlert } from 'react-alert';
import Countdown from 'react-countdown';
import moment from 'moment';

import SocketContext from '../../Components/socket_context/context';
import {
  soketValeLukk,
  soketLukkVabaks,
  soketOigeVastus,
} from '../../sockets/emit';

import './AvaPakk.css';

const AvaPakk8 = (props) => {
  const id = 8;
  const { mang } = useContext(SocketContext);
  const pakk = mang.pakid[id];
  const teade = useAlert();
  const [CarrieBlackum, setCarrieBlackum] = useState(false);
  const [AaliyahStark, setAaliyahStark] = useState(false);
  const [NaomiBurke, setNaomiBurke] = useState(false);
  const [PoppyChapmann, setPoppyChapmann] = useState(false);
  const [BailyBeck, setBailyBeck] = useState(false);
  const [LaurenReilly, setLaurenReilly] = useState(false);
  const [AbigailLarsen, setAbigailLarsen] = useState(false);
  const [ScarletLane, setScarletLane] = useState(false);
  const [SapphireScott, setSapphireScott] = useState(false);
  const [EmiliaWade, setEmiliaWade] = useState(false);
  const [HollieDavenport, setHollieDavenport] = useState(false);
  const [JenniferSchultz, setJenniferSchultz] = useState(false);
  const [MaryJane, setMaryJane] = useState(false);

  const params = new URLSearchParams(props.location.search);

  if (params.get('ava_pakk') === '8' && pakk.locked === false) {
    props.history.push('/pakk/8');
  }

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      soketLukkVabaks({ _id: mang._id, pakk_id: id });

      return <></>;
    } else {
      return (
        <>
          <div className='lukk_lukus'>
            <img alt='a' src='/images/lukk_lukus.png' />
            <div className='lukk_lukkus_text'>
              <b>{minutes * 60 + seconds}</b>
              <br />
              sekundit
            </div>
          </div>
        </>
      );
    }
  };

  const lisa_48_tundi = 60 * 2 * 1000;
  const algus_kell = moment(pakk.lukk_lukkus);
  const kk = algus_kell + lisa_48_tundi;

  const kontrolliVastust = (e) => {
    var vastus = [];

    if (CarrieBlackum) {
      vastus.push('CarrieBlackum');
    }
    if (AaliyahStark) {
      vastus.push('AaliyahStark');
    }
    if (NaomiBurke) {
      vastus.push('NaomiBurke');
    }
    if (PoppyChapmann) {
      vastus.push('PoppyChapmann');
    }
    if (BailyBeck) {
      vastus.push('BailyBeck');
    }
    if (LaurenReilly) {
      vastus.push('LaurenReilly');
    }
    if (AbigailLarsen) {
      vastus.push('AbigailLarsen');
    }
    if (ScarletLane) {
      vastus.push('ScarletLane');
    }
    if (SapphireScott) {
      vastus.push('SapphireScott');
    }
    if (EmiliaWade) {
      vastus.push('EmiliaWade');
    }
    if (HollieDavenport) {
      vastus.push('HollieDavenport');
    }
    if (JenniferSchultz) {
      vastus.push('JenniferSchultz');
    }
    if (MaryJane) {
      vastus.push('MaryJane');
    }

    if (vastus.toString() === 'ScarletLane') {
      teade.success('Õige vastus!');
      soketOigeVastus({ _id: mang._id, pakk_id: id });
    } else {
      teade.error('Vale vastus!');

      setCarrieBlackum('');
      setAaliyahStark('');
      setNaomiBurke('');
      setPoppyChapmann('');
      setBailyBeck('');
      setLaurenReilly('');
      setAbigailLarsen('');
      setScarletLane('');
      setSapphireScott('');
      setEmiliaWade('');
      setJenniferSchultz('');
      setMaryJane('');
      setHollieDavenport('');

      soketValeLukk({ _id: mang._id, pakk_id: id });
    }
  };

  return (
    params.get('ava_pakk') === '8' && (
      <Modal onClick={(e) => props.history.push(props.location.pathname)}>
        <button
          className='close-btn'
          onClick={(e) => props.history.push(props.location.pathname)}
        >
          x
        </button>
        <img alt='Can you escape?' className='lukk' src='/images/lukk.svg' />

        {pakk.lukk_lukkus ? (
          <>
            <h1>Tuleb oodata!</h1>
            <p
              style={{
                color: 'red',
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
            >
              Sisestasid liiga palju valesid vastuseid!
            </p>
          </>
        ) : (
          <>
            <h1>AVA LUKK</h1>
            <p>Vali 1 õige tegelane ja seejärel klikka "Kinnita"</p>
          </>
        )}

        {pakk.lukk_lukkus ? (
          <Countdown
            daysInHours={false}
            date={new Date(kk)}
            renderer={renderer}
          />
        ) : (
          <div className='pakk_ava_kahtlusalused'>
            <ul>
              <li
                onClick={(e) => setCarrieBlackum(!CarrieBlackum)}
                className={CarrieBlackum ? 'aktiivne' : null}
              >
                Carrie <b>Blackbum</b>
              </li>
              <li
                onClick={(e) => setAaliyahStark(!AaliyahStark)}
                className={AaliyahStark ? 'aktiivne' : null}
              >
                Aaliyah <b>Stark</b>
              </li>
              <li
                onClick={(e) => setNaomiBurke(!NaomiBurke)}
                className={NaomiBurke ? 'aktiivne' : null}
              >
                Naomi <b>Burke</b>
              </li>
              <li
                onClick={(e) => setPoppyChapmann(!PoppyChapmann)}
                className={PoppyChapmann ? 'aktiivne' : null}
              >
                Poppy <b>Chapman</b>
              </li>
              <li
                onClick={(e) => setBailyBeck(!BailyBeck)}
                className={BailyBeck ? 'aktiivne' : null}
              >
                Baily <b>Beck</b>
              </li>
              <li
                onClick={(e) => setLaurenReilly(!LaurenReilly)}
                className={LaurenReilly ? 'aktiivne' : null}
              >
                Lauren <b>Reilly</b>
              </li>
              <li
                onClick={(e) => setAbigailLarsen(!AbigailLarsen)}
                className={AbigailLarsen ? 'aktiivne' : null}
              >
                Abigail <b>Larsen</b>
              </li>
              <li
                onClick={(e) => setScarletLane(!ScarletLane)}
                className={ScarletLane ? 'aktiivne' : null}
              >
                Scarlet <b>Lane</b>
              </li>
              <li
                onClick={(e) => setSapphireScott(!SapphireScott)}
                className={SapphireScott ? 'aktiivne' : null}
              >
                Sapphire <b>Scott</b>
              </li>
              <li
                onClick={(e) => setEmiliaWade(!EmiliaWade)}
                className={EmiliaWade ? 'aktiivne' : null}
              >
                Emilia <b>Wade</b>
              </li>
              <li
                onClick={(e) => setHollieDavenport(!HollieDavenport)}
                className={HollieDavenport ? 'aktiivne' : null}
              >
                Hollie <b>Davenport</b>
              </li>
              <li
                onClick={(e) => setJenniferSchultz(!JenniferSchultz)}
                className={JenniferSchultz ? 'aktiivne' : null}
              >
                Jennifer <b>Schultz</b>
              </li>
              <li
                onClick={(e) => setMaryJane(!MaryJane)}
                className={MaryJane ? 'aktiivne' : null}
              >
                Mary <b>Jane</b>
              </li>
            </ul>
          </div>
        )}
        {pakk.lukk_lukkus ? (
          ''
        ) : (
          <div className='button-container'>
            <button onClick={kontrolliVastust}>Kinnita</button>
          </div>
        )}
      </Modal>
    )
  );
};

export default AvaPakk8;
/*
export default withTracker(() => {
  const { user } = useContext(UserContext);
  return {
    db: mangContainer.findOne({ user }),
  };
})(avaPakk8);
*/
