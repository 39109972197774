import React, { useState, useContext } from "react";
import Lightbox from "react-image-lightbox";
import { motion } from "framer-motion";
import { Link, Redirect } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import SocketContext from "../../Components/socket_context/context";

import { soketAvaVihje } from "../../sockets/emit";

import "react-image-lightbox/style.css";
import "./Pakid.css";

const Pakk4 = (props) => {
  const id = 4;
  const images = ["/images/uudis.jpg"];

  const { mang } = useContext(SocketContext);
  const [photoIndex, setphotoIndex] = useState(0);
  const [isOpen, setisOpen] = useState(false);

  var pakk = mang.pakid[id];
  var nextpakk = mang.pakid[id + 1];

  if (pakk.locked === true) {
    return <Redirect to={"/"} />;
  }

  const vihjeid = pakk.vihjeid;
  const vihjeid_veel = vihjeid.find((element) => !element.loetud);
  const avaVihje = async (e) => {
    e.preventDefault();
    const data = {
      _id: mang._id,
      pInx: parseInt(id),
      vInx: vihjeid_veel.id,
    };

    soketAvaVihje(data);
  };

  return (
    <motion.div initial="closed" animate="open" variants={sisu}>
      <div className="pakkPage">
        <h1 className="title">Asitõend</h1>
        <h3 className="subtitle">Pakk 4</h3>

        <div className={`kast bol`}>
          <p>Neljandast pakist leiad ühe lehekülje Top News ajalehest.</p>
          <ul className="failid">
            <li
              style={{ cursor: "pointer" }}
              onClick={() => {
                setphotoIndex(0);
                setisOpen(true);
              }}
            >
              {" "}
              <img alt="a" className="lukk" src="/images/fail.svg" /> Uudis
            </li>
          </ul>
        </div>

        <div className="kastButtons">
          <div className="vihjeButtonKast">

            <button
              disabled={!vihjeid_veel || pakk.vihjeteProovimineLukus}
              onClick={avaVihje}
            >
              Vajan vihjet <img alt="a" src="/images/canyouescape_icon.svg" />
            </button>
            ) : (

            <div className="vihjedRow">
              <div>
                {vihjeid.map(({ id, loetud }, ind) => {
                  return (
                    <div key={id}>
                      {vihjeid.length === ind + 1 ? (
                        <img
                          alt="a"
                          key={id}
                          width="15"
                          className={loetud ? "kasutatud" : null}
                          src="/images/flag_red.svg"
                        />
                      ) : (
                        <img
                          alt="a"
                          key={id}
                          width="15"
                          className={loetud ? "kasutatud" : null}
                          src="/images/flag_black.svg"
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div>
            <Link to={"/pakk/3"}>
              <button className="lyhemnupp">
                <FaAngleLeft />
              </button>
            </Link>
            {nextpakk.locked ? (
              <Link to={{ pathname: "/pakk/4", search: nextpakk.unlockLink }}>
                <button>Sain koodi kätte!</button>
              </Link>
            ) : (
              <Link to={nextpakk.link}>
                <button>järgmine asitõend</button>
              </Link>
            )}
          </div>
        </div>

        <div className="vihjed">
          <ul>
            {vihjeid.map(
              ({ id, vihje, loetud }) =>
                loetud && (
                  <li key={id}>
                    <div className="vihje_kast">
                      <img alt="a" src={`/images/vihje_${id + 1}.svg`} />{" "}
                      <span>VIHJE</span>
                      <p>{vihje}</p>
                    </div>
                  </li>
                )
            )}
          </ul>
        </div>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          //nextSrc={images[(photoIndex + 1) % images.length]}
          //prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setisOpen(false)}
          enableZoom={true}
        /*onMovePrevRequest={() =>
          setphotoIndex((photoIndex + images.length - 1) % images.length)
        }
        onMoveNextRequest={() =>
          setphotoIndex((photoIndex + 1) % images.length)
        }*/
        />
      )}
    </motion.div>
  );
};

export default Pakk4;
/*
export default withTracker(() => {
  const { user } = useContext(UserContext);
  return {
    db: mangContainer.findOne({ user }),
  };
})(Pakk4);
*/
const easing = [0.6, -0.05, 0.01, 0.99];

const sisu = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0,
      duration: 0.4,
      ease: easing,
    },
  },
  closed: { y: 60, opacity: 0 },
};
