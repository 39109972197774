import React, { useState, useEffect } from 'react';
import SocketContext from '../socket_context/context';

import { initSockets } from '../../sockets/index';
//       ^ initSockets is shown later on
const SocketProvider = (props) => {
  const [value, setValue] = useState({
    mang: JSON.parse(localStorage.getItem('mang')) || [],
  });

  useEffect(() => initSockets({ setValue }), [initSockets]);
  // Note, we are passing setValue ^ to initSockets
  return (
    <SocketContext.Provider value={value}>
      {props.children}
    </SocketContext.Provider>
  );
};
export default SocketProvider;
