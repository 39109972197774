import React from "react";
import { createPortal } from "react-dom";
import { motion } from "framer-motion";

import "./Modal.css";

const easing = [0.6, -0.05, 0.01, 0.99];
export const loginKast = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
  closed: { y: 60, opacity: 0 },
};
/*
const modalStyle = {
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: 'rgba(38, 38, 45, 0.93)',
  color: '#fff',
  fontSize: '40px',
  zIndex: '1001',
};*/

const Modal = (props) =>
  createPortal(
    <motion.div
      initial="closed"
      animate="open"
      variants={loginKast}
      className={`modal-page ${props.classnimi}`}
    >
      <motion.div
        initial="closed"
        animate="open"
        variants={loginKast}
        className="modal-container"
      >
        <div className="modal-inside-container">{props.children}</div>
      </motion.div>
      <div className="modal-close-" onClick={props.onClick}></div>
    </motion.div>,
    document.getElementById("modal_root")
  );

export default Modal;
