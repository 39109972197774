import React, { useContext } from "react";
//import { UserContext } from "../../Components/UserContext";
import "./KahtlusaluseNupp.css";

import SocketContext from "../../Components/socket_context/context";
import { muudaKahtlusalused } from "../../sockets/emit";

const KahtlusaluseNupp = ({ kahtlusaline }) => {
  //  const { user } = useContext(UserContext);
  const { mang } = useContext(SocketContext);
  const muudaStaatust = (kahtlusaline) => {
    muudaKahtlusalused({
      _id: mang._id,
      kahtlusaluseId: kahtlusaline._id,
      aktiivne: !kahtlusaline.aktiivne,
    });
  };

  return (
    <button
      className={`kahtlusaluse_nupp ${kahtlusaline.aktiivne ? "hide" : null}`}
      onClick={(e) => muudaStaatust(kahtlusaline)}
    >
      {kahtlusaline.aktiivne ? (
        <img
          alt="eemaldasid kahtlusaluste hulgast"
          src="/images/checkbox_selected.svg"
        />
      ) : (
        <img alt="kahtlusalune" src="/images/checkbox.svg" />
      )}
      {kahtlusaline.name}
    </button>
  );
};

export default KahtlusaluseNupp;
